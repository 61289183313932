<template>
    <div class="col-lg-3 mb-6" :class="filter.length">
        <label>{{ filter.label_lang_key !== undefined ? $t(`service.${filter.label_lang_key}`) : filter.label }}:</label>
        <input
            v-if="filter.type === 'text' || filter.type === 'number'"
            :type="filter.type"
            class="form-control"
            :placeholder="filter.placeholder_lang_key !== undefined ? $t(filter.placeholder_lang_key) : filter.placeholder"
            :name="filter.name"
            :value="value"
            @change="onChange"
        />
        <select
            v-if="filter.type === 'select'"
            class="form-control datatable-input"
            :name="filter.name"
            @change="onChange"
        >
            <option :selected="value == ''" value="">{{ $t("commons.all") }}</option>
            <option
                v-for="(option, optionIndex) in filter.options"
                :key="'option-' + optionIndex"
                :value="option.value"
                :selected="option.value === value"
            >
                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
            </option>
        </select>
        <div class="input-group" v-if="filter.type === 'date'">
            <input
                type="text"
                class="form-control"
                readonly="readonly"
                data-type="date"
                :name="filter.name"
                :value="value"
                :placeholder="filter.placeholder_lang_key !== undefined ? $t(filter.placeholder_lang_key) : filter.placeholder"
            />
            <div class="input-group-append cursor-pointer hover-opacity-80"
                @click="resetDate(filter.name)"
            >
                <span class="input-group-text">
                    <i class="la la-times-circle-o"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";

export default {
    name: "DatatableFilterInput",
    props: {
        filter: {
            type: Object,
            default: function () {
                return {};
            },
        },
        value: {
            type: [String, Array],
            default: ''
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', {
                name: event.target.name,
                value: event.target.value,
            });
        },
        resetDate(filterName) {
            $('input[data-type="date"]').data('daterangepicker').setStartDate('');
            $('input[data-type="date"]').data('daterangepicker').setEndDate('');
            this.$emit('input', {
                name: filterName,
                value: ''
            });
        }
    },
    mounted() {
        if (this.filter.type == "date") {
            const self = this;
            // predefined ranges
            /* const startDate = moment().subtract(29, "days");
            const endDate = moment(); */

            $('input[data-type="date"]').daterangepicker({
                buttonClasses: " btn",
                applyClass: "btn-primary",
                cancelClass: "btn-secondary",
                startDate: '',
                endDate: '',
                autoUpdateInput: false,
                locale: {
                    cancelLabel: self.$t("commons.cancel"),
                    applyLabel: self.$t("commons.approve"),
                    customRangeLabel: self.$t("dt_table.custom_range"),
                },
                ranges: {
                    [self.$t("commons.choose")]: ["", ""],
                    [self.$t("dt_table.today")]: [moment(), moment()],
                    [self.$t("dt_table.yesterday")]: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                    ],
                    [self.$t("dt_table.last_x_days", { days: 7 })]: [
                        moment().subtract(6, "days"),
                        moment(),
                    ],
                    [self.$t("dt_table.last_x_days", { days: 30 })]: [
                        moment().subtract(29, "days"),
                        moment(),
                    ],
                    [self.$t("dt_table.this_month")]: [
                        moment().startOf("month"),
                        moment().endOf("month"),
                    ],
                    [self.$t("dt_table.last_month")]: [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                    ],
                }
            }, function(start, end) {
                if(start._isValid && end._isValid) {
                    const value = start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");
                    $(this).val(value);
                    self.$emit('input', {
                        name: self.filter.name,
                        value
                    });
                    return;
                }
                $(this).val('');
                self.$emit('input', {
                    name: self.filter.name,
                    value: ''
                });
            });
        }
    }
};
</script>