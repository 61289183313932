<template>
    <div class="mb-12" v-if="this.filters.length > 0">
        <div class="row">
            <DatatableFilterInput v-for="(filter, filterIndex) in filters" :key="'filter-' + filterIndex" :filter="filter" v-model="selectedFilters[filter.name]" @input="getValue($event)" />
        </div>
        <div class="row">
            <div class="col-lg-12">
                <button
                    class="btn btn-primary btn-primary--icon"
                    @click="applyFilters"
                >
                    <span>
                        <i class="la la-search"></i>
                        <span>{{ $t("datatable.filter.search") }}</span>
                    </span></button
                >&nbsp;&nbsp;
                <button
                    class="btn btn-secondary btn-secondary--icon"
                    @click="resetFilters"
                >
                    <span>
                        <i class="la la-close"></i>
                        <span>{{ $t("datatable.filter.reset") }}</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import DatatableFilterInput from './DatatableFilterInput.vue';

export default {
    name: "DatatableFilter",
    data() {
        return {
            selectedFilters: {},
        }
    },
    props: {
        filters: {
            type: Array,
            default: function () {
                return [];
            },
        }
    },
    components: {
        DatatableFilterInput,
    },
    created() {
        this.filters.forEach(filter => {
            this.$set(this.selectedFilters, filter.name, '');
        });
    },
    methods: {
        getValue(value) {
            this.$set(this.selectedFilters, value.name, value.value);
        },
        applyFilters() {
            this.$emit('input', this.selectedFilters);
        },
        resetFilters() {
            this.selectedFilters = {};
            this.$set(this.selectedFilters, 'reset', true);
            this.applyFilters();
        },
    },
}
</script>