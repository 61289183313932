<template>
    <div>
        <DatatableFilter :filters="this.filters" @input="filterData($event)" v-if="filterEnabled" />
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div style="overflow-x: auto;">
                <table class="table table-striped table-bordered collapsed no-footer dataTable dtr-inline"
                    style="width: 100%">
                    <DatatableHead :columns="this.columns" @sort="sortData($event)">
                        <slot name="head"></slot>
                    </DatatableHead>
                    <DatatableBody :isLoading="this.isLoading" :columns="this.columns" :data="this.data">
                        <slot name="body"></slot>
                    </DatatableBody>
                </table>
            </div>
            <DatatablePagination v-if="!this.isLoading" :totalCount="this.data.total" :pageSize="this.data.perPage"
                :currentPage="this.data.currentPage" :lastPage="this.data.lastPage" @changePage="changePage($event)"
                @changePageSize="changePageSize($event)" />
        </div>
    </div>
</template>

<script>
import DatatableFilter from "./DatatableFilter.vue";
import DatatableHead from "./DatatableHead.vue";
import DatatableBody from "./DatatableBody.vue";
import DatatablePagination from "./DatatablePagination.vue";
import ServicesOld from "@/config/_axios_old";

export default {
    name: "Datatable",
    props: {
        url: {
            type: String,
            required: false,
        },
        dataUrl: {
            type: String,
            required: true,
        },
        filterEnabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isLoading: true,
            filters: [],
            selectedFilters: {},
            columns: [],
            data: {},
            page: 1,
            orderBy: '',
            orderDir: '',
            length: 10,
        };
    },
    components: {
        DatatableFilter,
        DatatableHead,
        DatatableBody,
        DatatablePagination
    },
    methods: {
        async loadDatatable() {
            if(this.url) {
                const res = await ServicesOld.get(this.url);
                this.filters = res.data.filters;
                this.columns = res.data.columns;
            }
        },
        async filterData(selectedFilters) {
            this.$set(this, "selectedFilters", selectedFilters);
            this.$set(this, "isLoading", true);
            this.loadData();
        },
        async loadData() {
            if (!this.isLoading) {
                this.$set(this, "isLoading", true);
            }

            if (this.selectedFilters.reset) {
                this.page = 1;
                this.length = 10;
                this.orderBy = '';
                this.orderDir = '';
                this.$delete(this.selectedFilters, 'reset');
            }

            let params = this.selectedFilters;
            params.page = this.page;
            params.orderBy = this.orderBy;
            params.orderDir = this.orderDir;
            params.length = this.length;

            const res = await ServicesOld.post(this.dataUrl, params);
            this.$set(this, "data", res.data);
            this.$emit("data", res.data.items);
            this.$set(this, "isLoading", false);
        },
        async sortData(column) {
            this.$set(this, "isLoading", true);
            this.$set(this, "orderBy", column.orderBy);
            this.$set(this, "orderDir", column.orderDir);
            this.loadData();
        },
        async changePage(page) {
            this.$set(this, "isLoading", true);
            this.$set(this, "page", page);
            this.loadData();
        },
        async changePageSize(length) {
            this.$set(this, "isLoading", true);
            this.$set(this, "length", length);
            this.loadData();
        }
    },
    created() {
        this.loadDatatable();
        this.loadData();
    },
    watch: {
        dataUrl: function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.loadData();
            }
        }
    }
};
</script>

<style scoped>
@media only screen and (max-width: 567px) {
    td {
        width: 100%;
        display: block;
    }
}
</style>
